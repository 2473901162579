<template>
    <div class="p-8 text-xs">
        <button @click="$router.push('/reseller')" class="flex items-center text-blue-500">
            <!-- Font Awesome for "chevron-left" -->
            <i class="fas fa-chevron-left w-6 h-6 mb-2"></i>
            <span class="ml-2 mb-5">Kembali</span>
        </button>
        <div class="rounded-2xl bg-white p-8">

            <div class="">
                <div>
                    <div class="">
                        <span class="font-semibold text-base">Tambah Reseller</span>
                    </div>
                    <div class="grid grid-cols-12 gap-4 mt-4">
                        <!-- <div class="col-span-3">
                           <div class="flex justify-center border border-gray-200">
                               <div class="w-40 h-40 ">
                                   <img :src="default_url + 'default.png'" class="w-40 h-40 object-cover " @click="$refs.foto_profile.click()"/>

                               </div>
                           </div>
                       </div> -->
                        <div class="col-span-12">
                            <input type="file" style="display:none; " id="foto_profile" ref="foto_profile"
                                accept="image/*" @change="eventChange">

                            <ValidationObserver v-slot="{ invalid }" ref="form">
                                <form @submit.prevent="postData()" class="w-full">
                                    <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
                                        <ValidationProvider rules="required" v-slot="{ errors }" name="Nama" vid="name"
                                            class="grid grid-cols-12 gap-4 items-center">
                                            <label for="" class="block mb-2 col-span-2">Nama*</label>
                                            <div class="col-span-10">
                                                <input v-model="addData.name" type="text"
                                                    :class="`block border border-gray-200 w-full p-2 px-4 rounded  text-xs ${errors[0] ? 'border-red-300 bg-red-50 placeholder-red-200 text-red-900 focus:border-red-300' : ''}`"
                                                    name="name" ref="name" id="name" />
                                                <span class="text-red-600 text-xs col-span-10">{{ errors[0] }}</span>
                                            </div>
                                        </ValidationProvider>

                                        <ValidationProvider rules="required" v-slot="{ errors }" name="Nama Perusahaan"
                                            vid="company_name" class="grid grid-cols-12 gap-4 items-center">
                                            <label for="" class="block mb-2 col-span-2">Nama Perusahaan*</label>
                                            <div class="col-span-10">
                                                <input v-model="addData.company_name" type="text"
                                                    :class="`block border border-gray-200 w-full p-2 px-4 rounded  text-xs ${errors[0] ? 'border-red-300 bg-red-50 placeholder-red-200 text-red-900 focus:border-red-300' : ''}`"
                                                    name="company_name" ref="company_name" id="company_name" />
                                                <span class="text-red-600 text-xs col-span-10">{{ errors[0] }}</span>
                                            </div>
                                        </ValidationProvider>
                                    </div>

                                    <div class="grid grid-cols-1 md:grid-cols-2 gap-4 mt-4">
                                        <ValidationProvider rules="required" v-slot="{ errors }" name="Email"
                                            vid="email" class="grid grid-cols-12 gap-4 items-center">
                                            <label for="" class="block mb-2 col-span-2">Email*</label>
                                            <div class="col-span-10">
                                                <input v-model="addData.email" type="text"
                                                    :class="`block border border-gray-200 w-full p-2 px-4 rounded  text-xs ${errors[0] ? 'border-red-300 bg-red-50 placeholder-red-200 text-red-900 focus:border-red-300' : ''}`"
                                                    name="email" ref="email" id="email" />
                                                <span class="text-red-600 text-xs col-span-10">{{ errors[0] }}</span>
                                            </div>
                                        </ValidationProvider>

                                        <ValidationProvider rules="required" v-slot="{ errors }" name="Nomor Telepon"
                                            vid="phone" class="grid grid-cols-12 gap-4 items-center">
                                            <label for="" class="block mb-2 col-span-2">Nomor Telepon*</label>
                                            <div class="col-span-10">
                                                <input v-model="addData.phone" type="text"
                                                    :class="`block border border-gray-200 w-full p-2 px-4 rounded  text-xs ${errors[0] ? 'border-red-300 bg-red-50 placeholder-red-200 text-red-900 focus:border-red-300' : ''}`"
                                                    name="phone" ref="phone" id="phone" placeholder="Nama" />
                                                <span class="text-red-600 text-xs col-span-10">{{ errors[0] }}</span>
                                            </div>
                                        </ValidationProvider>
                                    </div>

                                    <div class="grid grid-cols-1 md:grid-cols-2 gap-4 mt-4">
                                        <ValidationProvider v-slot="{ errors }" name="Website" vid="website"
                                            class="grid grid-cols-12 gap-4 items-center">
                                            <label for="" class="block mb-2 col-span-2">Website</label>
                                            <div class="col-span-10">
                                                <input v-model="addData.website" type="text"
                                                    :class="`block border border-gray-200 w-full p-2 px-4 rounded  text-xs ${errors[0] ? 'border-red-300 bg-red-50 placeholder-red-200 text-red-900 focus:border-red-300' : ''}`"
                                                    name="website" ref="website" id="website" />
                                                <span class="text-red-600 text-xs col-span-10">{{ errors[0] }}</span>
                                            </div>
                                        </ValidationProvider>

                                    </div>

                                    <div class="grid grid-cols-1 md:grid-cols-3 gap-4 mt-4">
                                        <ValidationProvider name="Password Lama" vid="prov_id"
                                            class="grid grid-cols-12 gap-4 items-center">
                                            <label for="" class="block mb-2 col-span-2 md:col-span-3">Provinsi</label>
                                            <div class="col-span-10 md:col-span-9">
                                                <select ref="prov_id" name="prov_id"
                                                    class="block border border-gray-200 w-full p-2 px-4 rounded text-xs"
                                                    v-model="addData.prov_id" @change="changeProv()">
                                                    <option :value="item.prov_id"
                                                        class="block border border-gray-200 w-full p-2 px-4 rounded text-xs"
                                                        v-for="item in province" :key="item.prov_id">{{ item.prov_name }}
                                                    </option>
                                                </select>

                                            </div>
                                        </ValidationProvider>

                                        <ValidationProvider name="Kota" vid="city_id"
                                            class="grid grid-cols-12 gap-4 items-center">
                                            <label for="" class="block mb-2 col-span-2 md:col-span-3">Kota</label>
                                            <div class="col-span-10 md:col-span-9">
                                                <select ref="prov_id" name="prov_id"
                                                    class="block border border-gray-200 w-full p-2 px-4 rounded text-xs"
                                                    v-model="addData.city_id" @change="changeCity()">
                                                    <option :value="item.city_id"
                                                        class="block border border-gray-200 w-full p-2 px-4 rounded text-xs"
                                                        v-for="item in city" :key="item.city_id">{{ item.city_name }}
                                                    </option>
                                                </select>

                                            </div>
                                        </ValidationProvider>

                                        <ValidationProvider name="Kabupaten" vid="dis_id"
                                            class="grid grid-cols-12 gap-4 items-center">
                                            <label for="" class="block mb-2 col-span-2 md:col-span-3">Kabupaten</label>
                                            <div class="col-span-10 md:col-span-9">
                                                <select ref="prov_id" name="prov_id"
                                                    class="block border border-gray-200 w-full p-2 px-4 rounded text-xs"
                                                    v-model="addData.dis_id">
                                                    <option :value="item.dis_id"
                                                        class="block border border-gray-200 w-full p-2 px-4 rounded text-xs"
                                                        v-for="item in district" :key="item.dis_id">{{ item.dis_name }}
                                                    </option>
                                                </select>

                                            </div>
                                        </ValidationProvider>
                                    </div>

                                    <div class="grid grid-cols-1 gap-4 mt-4">
                                        <ValidationProvider v-slot="{ errors }" name="Alamat" vid="alamat"
                                            class="grid grid-cols-12 gap-4 items-center">
                                            <label for="" class="block mb-2 col-span-2 md:col-span-1">Alamat</label>
                                            <div class="col-span-10 md:col-span-11">

                                                <textarea value="Hello world" v-model="addData.address"
                                                    :class="`block border border-gray-200 w-full p-2 px-4 rounded text-xs ${errors[0] ? 'border-red-300 bg-red-50 placeholder-red-200 text-red-900 focus:border-red-300' : ''}`"
                                                    name="my-textarea" />
                                                <span class="text-red-600 text-xs col-span-10">{{ errors[0] }}</span>
                                            </div>
                                        </ValidationProvider>
                                    </div>
                                    <div class="grid grid-cols-1 md:grid-cols-2 gap-4 mt-4">
                                        <ValidationProvider rules="required" v-slot="{ errors }" name="Tanggal Mulai"
                                            vid="start_date" class="grid grid-cols-12 gap-4 items-center">
                                            <label for="" class="block mb-2 col-span-2">Tanggal Mulai*</label>
                                            <div class="col-span-10">
                                                <input v-model="addData.start_date" type="date"
                                                    :class="`block border border-gray-200 w-full p-2 px-4 rounded  text-xs ${errors[0] ? 'border-red-300 bg-red-50 placeholder-red-200 text-red-900 focus:border-red-300' : ''}`"
                                                    name="start_date" ref="start_date" id="start_date"
                                                    placeholder="Nama" />
                                                <span class="text-red-600 text-xs col-span-10">{{ errors[0] }}</span>
                                            </div>
                                        </ValidationProvider>

                                        <ValidationProvider rules="required|numeric" v-slot="{ errors }"
                                            name="Tahun Kontrak" vid="contract"
                                            class="grid grid-cols-12 gap-4 items-center">
                                            <label for="" class="block mb-2 col-span-2">Tahun Kontrak*</label>
                                            <div class="col-span-10">
                                                <input v-model="addData.contract" type="text"
                                                    :class="`block border border-gray-200 w-full p-2 px-4 rounded  text-xs ${errors[0] ? 'border-red-300 bg-red-50 placeholder-red-200 text-red-900 focus:border-red-300' : ''}`"
                                                    name="contract" ref="contract" id="contract"
                                                    placeholder="contoh : 1, 2,3" />
                                                <span class="text-red-600 text-xs col-span-10">{{ errors[0] }}</span>
                                            </div>
                                        </ValidationProvider>
                                    </div>

                                    <div class="grid grid-cols-1 gap-4 mt-4">
                                        <ValidationProvider v-slot="{ errors }" name="Catatan" vid="note"
                                            class="grid grid-cols-12 gap-4 items-center">
                                            <label for="" class="block mb-2 col-span-2 md:col-span-1">Catatan</label>
                                            <div class="col-span-10 md:col-span-11">

                                                <textarea value="Hello world" v-model="addData.note"
                                                    :class="`block border border-gray-200 w-full p-2 px-4 rounded text-xs ${errors[0] ? 'border-red-300 bg-red-50 placeholder-red-200 text-red-900 focus:border-red-300' : ''}`"
                                                    name="my-textarea" />
                                                <span class="text-red-600 text-xs col-span-10">{{ errors[0] }}</span>
                                            </div>
                                        </ValidationProvider>
                                    </div>

                                    <div class="grid grid-cols-1 gap-4 mt-4">
                                        <div class="grid grid-cols-12 gap-4 items-center">
                                            <label for="" class="mb-2 col-span-2 md:col-span-1">Logo/Gambar
                                                Perusahaan</label>
                                            <div class="col-span-10 md:col-span-3">
                                                <div>
                                                    <div class="flex justify-center border border-gray-200 p-4"
                                                        @click="$refs.foto_profile.click()">
                                                        <div class="w-40 h-40 ">
                                                            <img :src="addData.foto ? addData.foto : default_url + 'default.png'"
                                                                class="w-40 h-40 object-cover " />
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>

                                    <div class="float-right">
                                        <button @click="$router.push('/reseller')" :class="`text-center py-2 px-8 rounded
                                
                                border border-gray
                                mr-2
                                hover:bg-gray-100
                                disabled:opacity-50
                                mt-4
                                
                                focus:outline-none my-1`">Batal</button>

                                        <button :disabled="invalid" type="submit" :class="`text-center py-2 px-8 rounded
                                text-white
                                bg-blue-500
                                disabled:opacity-50
                                mt-4
                                
                                focus:outline-none my-1`">Simpan</button>
                                    </div>


                                </form>
                            </ValidationObserver>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>
<script>
import { mapGetters, mapAction, mapActions } from 'vuex'
import { ValidationProvider, ValidationObserver } from "vee-validate/dist/vee-validate.full";
import globalMixin from '../../mixin/global'
import moment from 'moment'
export default {
    data: () => ({
        data: [],
        totalRows: 100,
        perPage: 10,
        limit: 5,
        currentPage: 1,
        disabled: false,
        modal_dialog: false,
        keyword: '',
        foto: '',
        roles: [],
        province: [],
        city: [],
        district: [],
        addData: {
            email: '',
            name: '',
            company_name: '',
            phone: '',
            contract: '',
            foto: '',
            website: '',
            prov_id: '',
            city_id: '',
            dis_id: '',
            address: '',
            start_date: moment().format('YYYY-MM-DD').toString(),
            note: '',
        },
        delete_id: '',
        modal_edit: false,
        default_url: process.env.VUE_APP_IMAGES
    }),
    mixins: [globalMixin],
    methods: {

        changeProv() {
            this.district = []
            this.addData.city_id = ''
            this.addData.dis_id = ''
            this.getCity()
        },
        changeCity() {
            this.addData.dis_id = ''
            this.getDis()
        },
        async postData() {
            let data = new FormData()
            var request_form = this.addData
            await Object.keys(this.addData).forEach(function (key, val) {
                console.log(key)
                if (key != 'foto') {
                    data.append(key, request_form[key])

                }
            });

            if (this.foto) {
                data.append('foto', this.foto)
            } else {
                data.append('foto', '')
            }

            this.axios.post('v1/reseller', data, this.config)
                .then((ress) => {
                    this.$snack.success({
                        text: ress.data.message,

                    })
                    this.$router.push('/reseller')
                })
                .catch(err => {
                    if (err.response.status == 400) {
                        this.$refs.form.setErrors(err.response.data.message)
                    }
                })

        },
        eventChange(event) {
            this.default_url = ''
            const files = event.target.files
            this.foto = files[0]
            const fileReader = new FileReader()
            fileReader.addEventListener('load', () => {
                this.addData.foto = fileReader.result
            })
            fileReader.readAsDataURL(this.foto)
        },

    },
    computed: {
        ...mapGetters({
            token: 'auth/token',
        }),

    },
    created() {
        this.getProv()
    },
    components: {
        ValidationProvider, ValidationObserver
    }
}
</script>